import { Seo, Text } from 'components';
import Header from 'components/Header';

import Footer from 'components/Footer';
import Overlay from 'pages/start/quiz/components/Overlay';
import React, { useState } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { SocialLinks } from 'utils/externalLinks';

import { useRouter } from 'apis/history';
import { Routes } from 'utils/constants/routes';
import { Color, Typography } from 'utils/theme';

type LayoutProps = {
  children: React.ReactNode;
  rightComponent: React.ReactNode;
  seoTitle?: string;
  seoDescription?: string;
  seoName?: string;
  logoVariant?: string;
  isMobileLink?: boolean;
  hideFooter?: boolean;
  bgColor?: Color;
  isWhite?: boolean;
  bannerText?: string;
  isStickyHeader?: boolean;
  bannerColorsInverted?: boolean;
  hideHeader?: boolean;
};

const Container = styled.main`
  width: 100%;
  /* overflow: hidden; */
`;

const MainContainer = styled.div<{ hideHeader?: boolean }>`
  width: 100vw;
  background-size: cover;
  max-width: 100%;
  ${({ hideHeader }) => hideHeader && 'display: none;'}
`;

const NavRightContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 4.5rem;
  align-items: center;

  @media ${tablet} {
    grid-template-columns: auto;
  }
`;

const NavRightLinksContainer = styled.div`
  display: flex;
  align-items: center;
  @media ${tablet} {
    display: none;
  }
`;

const NavRightStoresContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 1.5rem;
  align-items: center;
  .active-menu {
    visibility: visible;
    pointer-events: auto;
    opacity: 1;
    backdrop-filter: blur(24px);
  }
  .menu-trigger {
    position: absolute;
    top: 10px;
    width: 48px;
    height: 48px;
    padding: 17px 16px;
    right: 4px;
    z-index: 100;
    cursor: pointer;
    display: block;

    span {
      display: block;
      margin-bottom: 4px;
      transition: all 0.2s ease;
      height: 2px;
      border-radius: 5px;
      background: #1c1c28;
      &:nth-child(1) {
        width: 50%;
        margin-left: 50%;
      }
      &:nth-child(3) {
        width: 75%;
        margin-left: auto;
      }
      .active-menu &:nth-child(2) {
        opacity: 0;
      }
      .active-menu &:nth-child(1) {
        transform: rotate(-45deg);
        margin: 7px 0 -8px;
        width: 100%;
        border-radius: 0;
      }
      .active-menu &:nth-child(3) {
        transform: rotate(45deg);
        border-radius: 0;
        width: 100%;
      }
    }
  }
`;

const StyledContainer = styled.div`
  cursor: pointer;
  margin: 0 1.5rem;
  white-space: nowrap;
`;

const Layout = ({
  children,
  isMobileLink,
  hideFooter,
  logoVariant = 'left',
  seoTitle,
  seoDescription,
  seoName,
  rightComponent,
  bgColor = 'novaPink',
  isWhite = false,
  bannerText,
  isStickyHeader,
  bannerColorsInverted,
  hideHeader,
}: LayoutProps) => {
  const [show, setShow] = useState<boolean>(false);

  const { isTablet } = useQuery();

  const {
    goToContact,
    goToContactMobile,
    goToReviews,
    goToFAQ,
    goToStarterGuide,
    goToStory,
  } = useRouter();

  const renderRightComponent = () => (
    <NavRightContainer>
      <NavRightLinksContainer>
        {/* <StyledContainer onClick={() => goToFAQ()}>
          <Text type="body">FAQ</Text>
        </StyledContainer>
        <StyledContainer onClick={() => goToStarterGuide()}>
          <Text type="body">Starter Guide</Text>
        </StyledContainer> */}
        <StyledContainer
          onClick={() => (isMobileLink ? goToContactMobile() : goToContact())}
        >
          <Text type="body">Contact Us</Text>
        </StyledContainer>
      </NavRightLinksContainer>
      <NavRightStoresContainer>
        {isTablet && (
          <div
            className={`menu-trigger`}
            onClick={() => setShow(prev => !prev)}
          >
            <span />
            <span />
            <span />
          </div>
        )}
      </NavRightStoresContainer>
    </NavRightContainer>
  );

  return (
    <Container>
      <Seo title={seoTitle} description={seoDescription} name={seoName} />

      <MainContainer
        className={show ? 'active-menu' : ''}
        hideHeader={hideHeader}
      >
        <Header
          logoVariant={logoVariant}
          isWhite={isWhite}
          color={bgColor}
          bannerTopText={bannerText}
          sticky={isStickyHeader}
          bannerColorsInverted={bannerColorsInverted}
        />
      </MainContainer>

      {/* {show && !isMobileLink && (
        <Overlay
          zIndex={1}
          navLinks={[
            { title: 'FAQ', url: Routes.FAQ },
            {
              title: 'Starter Guide',
              url: Routes.StarterGuide,
            },
            {
              title: 'Contact Us',
              url: Routes.ContactUs,
            },
          ]}
          socialLinks={[
            {
              svgId: 'f-fb',
              url: SocialLinks.Facebook,
            },
            {
              svgId: 'f-instagram',
              url: SocialLinks.Instagram,
            },
          ]}
        />
      )} */}
      {children}
      {!hideFooter && <Footer isMobileLink={isMobileLink} />}
    </Container>
  );
};

export default Layout;
